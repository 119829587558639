//use all fama, famaliving
let Cookies = {
    init: function () {
        console.log('Cookies init()');
        this.checkAcceptedCookies();
    },

    checkAcceptedCookies: function() {
        const cookieKey = $('#lcc-modal-').data('cookie-key');

        const cookiesAcceptedString = this.getCookie(cookieKey);
        if(!cookiesAcceptedString) {
            return;
        }
        const cookiesAccepted = cookiesAcceptedString.split(',');
        if(cookiesAccepted.includes('cookies_analytics')) {
            this.loadAnalytics();
        }
    },

    loadAnalytics: function () {
        // this.enableGoogleTagManager();
        this.enableGoogleUniversal();
        this.enableAWGoogle();
        this.enableFacebookPixel();
        this.enableCastellonPixels();
    },

    enableAWGoogle: function () {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'AW-965718554',{ 'allow_enhanced_conversions' : true });
    },

    enableGoogleTagManager: function() {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',this.getGoogleTagManagerCode());
    },

    enableGoogleUniversal: function() {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-18558806-1');
    },

    enableFacebookPixel: function() {
        let metaCodes = [];

        const fbCode1 = $('#lcc-modal-').data('facebook-code');
        const fbCode2 = $('#lcc-modal-').data('facebook2-code');
        const fbValladolid = $('#lcc-modal-').data('fb-valladolid');
        if(fbCode1 !== undefined) {
            metaCodes.push(fbCode1);
        }
        if(fbCode2 !== undefined) {
            metaCodes.push(fbCode2);
        }
        if(fbValladolid !== undefined) {
            metaCodes.push(fbValladolid);
        }
        const body = document.getElementsByTagName('body')[0];
        //console.log('asdsadaasd------------->', metaCodes);
        metaCodes.forEach(function (code) {
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];n
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', code);
            fbq('track', 'PageView');
            let img = document.createElement('img');
            img.height = 1;
            img.width = 1;
            img.style.display = 'none';
            img.src = 'https://www.facebook.com/tr?id=' + code + '&ev=PageView&noscript=1';
            body.appendChild(img)
        });
    },

    enableCastellonPixels: function() {
        const gtagCastellon = $('#lcc-modal-').data('gtag-castellon');
        const oniadCastellon = $('#lcc-modal-').data('oniad-castellon');
        if(gtagCastellon !== undefined) {
            let firstScript = document.getElementsByTagName('script')[0]
            let newScript = document.createElement('script');
            newScript.async = true;
            newScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gtagCastellon;
            firstScript.parentNode.insertBefore(newScript, firstScript);

            window.dataLayer = window.dataLayer || [];
            if(typeof gtagCastellon !== 'function') {
                function gtag(){
                    dataLayer.push(arguments);
                }
            }
            gtag('js', new Date());
            gtag('config', gtagCastellon);
        }
        if(oniadCastellon !== undefined) {
            let firstScript = document.getElementsByTagName('script')[0]
            let newScript = document.createElement('script');
            newScript.async = true;
            newScript.src = 'https://tag.oniad.com/' + oniadCastellon;
            firstScript.parentNode.insertBefore(newScript, firstScript);
        }
    },

    getGoogleTagManagerCode: function () {
        return $('#lcc-modal-').data('gtag-code');
    },

    getGoogleUniversaCode: function () {
        return $('#lcc-modal-').data('universal-code');
    },

    binds: function () {
        const self = this;
        $('#lcc-modal-button-accept').click(function() {
            //$('body').removeClass('stop-scrolling');
            self.checkAcceptedCookies();
        })
        $('.js-lcc-accept-all').click(function() {
            //$('body').removeClass('stop-scrolling');
            self.checkAcceptedCookies();
        })
        $('.js-lcc-settings-save').click(function() {
            //$('body').removeClass('stop-scrolling');
            self.checkAcceptedCookies();
        })
    },

    getCookie(key) {
        if (!key) return null;
        return (
            decodeURIComponent(
                document.cookie.replace(
                    new RegExp(
                        '(?:(?:^|.*;)\\s*' +
                        encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') +
                        '\\s*\\=\\s*([^;]*).*$)|^.*$'
                    ),
                    '$1'
                )
            ) || null
        );
    }

}

$(document).ready(function () {
    Cookies.binds();
    Cookies.init();
});
